import React from "react"
import variables from "../GlobalVariables"
import styled from "styled-components"

const TitleStyle = styled.div`
  /* h3::first-letter,
  h4::first-letter {
    color: ${variables.accentColor};
  } */

  h1 {
    color: ${variables.accentColor};
    font-size: 3rem;
  }

  h2 {
    color: ${variables.accentColor};
    font-size: 3rem;
  }

  h3{
font-size: 2rem ;
  }

  @media (max-width: ${variables.smallScreen}) {
  }
`

const title = props => {
  return (
    <TitleStyle className="Title">
      {generateTitle(props.size, props.children)}
    </TitleStyle>
  )
}

const generateTitle = (size, text) => {
  switch (size) {
    case "s":
      return <h4>{text}</h4>
    case "m":
      return <h3>{text}</h3>
    case "l":
      return <h2>{text}</h2>
    default:
      return <h1>{text}</h1>
  }
}

export default title
