const variables = {
  darkColor: "#000000",
  greyColor: "#1d1d1d",
  slightlyGreyColor: "#181818",
  clearColor: "#f4f4f4",
  accentColor: "#ed6f07",

  headerFont: "'Poppins', sans-serif;",
  paragraphFont: "'Open Sans', sans-serif",

  roundedRadius: "20px",

  smallScreen: "501px",
  mediumScreen: "1330px",
}

export default variables
